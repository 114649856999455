@import '../../../constants';

.about {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
    transition: 1s border-radius ease;

    .margin {
        position: absolute;
		top: 0;
		left: 0;
        height: 100vh;
        width: 100vw;
        transition: 1s opacity ease;
        background-color: rgba(35, 35, 35, 0.314);
        opacity: 0;
    }

    .modal {
        transition: 1s border-radius ease;
        background-color: rgba(35, 35, 35, 0.878);
        max-height: 90vh;
        width: 90vw;
        border-radius: 100%;
        box-shadow: #232323 0px 4px 24px;
        color: white;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;

        .close {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: #cccccc;
            box-shadow: #131313 0px 2px 10px;
            color: #5e5e5e;
            height: 25px;
            width: 25px;
            border-radius: 12.5px;
            z-index: 99999;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.2s;
            transition: 1s opacity ease;
            opacity: 0;
    
            &:hover {
                cursor: pointer;
                transform: scale(1.05);
                transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1)
            }
    
            &:active {
                transform: scale(0.9);
                background-color: #aaaaaa;
            }
        }

        .content {
            position: relative;
            color: #cdcdcd;
            overflow-y: auto;
            overflow-x: hidden;
            transition: 1.5s opacity ease-in;
            max-height: calc(90vh - 50px);

            .inner-content {
                float: right;
                display: flex; 
                flex-direction: column;
                justify-content: flex-end;
                background-color: #23232390;
                box-shadow: inset 0 0 10px  #1b1b1b;
                border-radius: 5px;
                margin-left: 30px;
                padding: 15px 20px;

                @media only screen and (max-width: 714px) {
                    padding: 30px 20px;
                }

                @media only screen and (max-width: 675px) {
                    padding: 45px 20px;
                }

                @media only screen and (max-width: 619px) {
                    padding: 45px 0px;
                    padding-right: 15px;
                }

                @media only screen and (max-width: 591px) {
                    padding: 60px 0px;
                    padding-right: 15px;
                }

                @media only screen and (max-width: 584px) {
                    padding: 15px 20px;
                    width: calc(100% - 40px);
                    margin-bottom: 20px;
                    margin-left: 0;
                }
            }

            span {
                font-size: 2em;
                margin-bottom: -20px;
            }

            a {
                text-decoration: none;
                color: #ffc900;
                font-weight: 700;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .row {
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: row;
                text-align: center;
                padding: 10px 0;

                .text {
                    padding: 0 20px;
                }
            }
        }
    }

    .bubble {
        transition: 1s border-radius ease;
        border-radius: 100%;
    }
}
