@import '../../../constants';

.project {
    min-height: calc(100vh - 70px);
    width: calc(100% - 120px);
    max-width: 2000px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 10px;
    padding-left: 110px;
    padding-top: 10px;
    padding-bottom: 60px;

    @media only screen and (max-width: 700px) {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        max-width: 100%;
        padding-top: 0px;
    }

    .animated {
        min-height: calc(100vh - 100px);
        width: calc(100vw - 50px);
    }

    .project-cards {
        width: 100%;

        span {
            flex: 1 0 21%;
            display: flex;
            flex-wrap: wrap;
            opacity: 0.99;

            @media only screen and (max-width: 1400px) {
                flex: 1 0 29%;
            }

            @media only screen and (max-width: 1000px) {
                flex: 1 0 45%;
            }

            @media only screen and (max-width: 700px) {
                flex-direction: column;
                flex-wrap: nowrap;
            }
        }

        .project-card-anim-enter {
            opacity: 0.01;
            filter: blur(10px);
            transform: scale(0.5);
        }

        .project-card-anim-enter.project-card-anim-enter-active {
            opacity: 0.99;
            filter: blur(0px);
            transform: scale(1);
            transition: filter opacity transform 2000ms ease-out;
        }

        .project-card-anim-leave {
            opacity: 0.99;
            transform: scale(1);
            filter: blur(0px);
        }

        .project-card-anim-leave.project-card-anim-leave-active {
            opacity: 0.01;
            filter: blur(10px);
            transform: scale(0.5);
            transition: filter opacity transform 1000ms ease-in;
        }
    }
}