@import '../../../constants';

.prog-language {
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    -webkit-transform: translate3d(0, 0, 0);
    
    @media only screen and (min-width: 2120px) {
        left: calc((100vw - 2000px) / 2 - 60px);
    }

    .description {
        // margin-bottom: 15px;
        // width: calc(100vw - 40px); 

        p {
            color: #232323;
            text-align: center;
            font-size: 0.8em;
            font-weight: 500;
        }
    }

    @media only screen and (max-width: 700px) {
        position: relative;
        // max-width: 100vw;
        max-width: calc(100%); 

        span {
            padding-bottom: 10px;
            max-width: calc(100%); 
            // padding-right: 20px;
        }

        .description {
            align-self: center;
            max-width: calc(100%); 
            // padding-right: 20px;
        }
    }

    .selected {
        opacity: 0.99;
    }

    .project-lang-anim-enter {
        opacity: 0.01;
        filter: blur(10px);
        transform: scale(0.5);
    }

    .project-lang-anim-enter.project-lang-anim-enter-active {
        opacity: 0.99;
        filter: blur(0px);
        transform: scale(1);
        transition: filter opacity transform 2000ms ease-out;
    }

    .project-lang-anim-leave {
        opacity: 0.99;
        transform: scale(1);
        filter: blur(0px);
    }

    .project-lang-anim-leave.project-lang-anim-leave-active {
        opacity: 0.01;
        filter: blur(10px);
        transform: scale(0.5);
        transition: filter opacity transform 1000ms ease-in;
	}
	
	div {
		transition: filter 0.5s ease;
        max-width: 90px;
        @media only screen and (max-width: 700px) {
            max-width: 100%;
        }
	}

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        @media only screen and (max-width: 700px) {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 0px;
        }

        div {
            background-color: #232323;
            border-radius: 30px;
            margin: 5px;
            opacity: 0.5;
            transition: opacity 100ms ease-in;
            box-shadow: rgba(35, 35, 35, 0.314) 0px 2px 2px;

            &:hover {
                cursor: pointer;
                opacity: 0.8;
                transition: opacity 200ms ease-out;
            }

            p {
                user-select: none;
                font-size: 12px;
                color: white;
                padding: 5px 9px;
            }
        }
    }
}