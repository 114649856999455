.project-readme {
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 200;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;

    .animated {
        display: flex;
        justify-content: center;
    }

    .margin {
        position: absolute;
		top: 0;
		left: 0;
        height: 100vh;
        width: calc(100vw);
        transition: 1s opacity ease;
        opacity: 0;
        background-color: rgba(35, 35, 35, 0.314);
    }

    .close {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #cccccc;
        box-shadow: #131313 0px 2px 10px;
        color: #5e5e5e;
        height: 25px;
        width: 25px;
        border-radius: 12.5px;
        z-index: 99999;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition-duration: 0.2s;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1)
        }

        &:active {
            transform: scale(0.9);
            background-color: #aaaaaa;
        }
    }

    .modal {
        transition: height 0.5s ease;
        position: relative;
        background-color: rgba(35, 35, 35, 0.878);
        height: fit-content;
        width: 80vw;
        max-width: 1000px;
        max-height: 80vh;
        box-shadow: #232323 0px 2px 10px;
        border-radius: 5px;
        padding: 25px;
        color: #cdcdcd;

        @media only screen and (max-width: 700px) {
            margin: 5vh 2vw;
        }

        a {
            font-weight: 700;
            color: #cccccc;

            &:hover {
                color: #777777;
            }
        }

        .link {
            position: absolute;
            bottom: -25px;
            right: -15px;
            background-color: #cccccc;
            box-shadow: #131313 0px 2px 10px;
            color: #5e5e5e;
            height: 50px;
            width: 50px;
            border-radius: 25px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.2s;

            @media only screen and (max-width: 500px) {
                height: 40px;
                width: 40px;
                right: -8px;
                svg {
                    height: 20px;
                    width: 20px;
                }

                @media only screen and (max-height: 600px) {
                    bottom: -15px;
                }
            }
    
            &:hover {
                cursor: pointer;
                transform: scale(1.05);
                transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1)
            }
    
            &:active {
                transform: scale(0.9);
                background-color: #aaaaaa;
            }
        }

        div {
            transition: height 0.5s ease;
        }

        article {
            overflow: auto;
            max-height: 80vh;
			animation: fadeIn 2s ease;
			width: 100%;

            img {
                max-width: 100%;
            }

            code {
                background-color: rgba(221, 221, 221, 0.314);
                border-radius: 3px;
                border-color: rgba(119, 119, 119, 0.439);
                border-width: 1px;
                font-size: 0.8em;
                padding: 1px 3px;
                border-style: solid;
                font-family: 'Raleway';
            }

            pre {
                background-color: rgba(221, 221, 221, 0.314);
                border-radius: 3px;
                border-color: rgba(119, 119, 119, 0.439);
                border-width: 1px;
                padding: 1px 3px;
                border-style: solid;

                code {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }
    }

    .not-found {
        height: fit-content;
        width: fit-content;
        max-width: 300px;
        text-align: center;
    }

    .loading {
        max-width: 300px;

        .link {
            opacity: 0;
        }
	}
	
	
    .lds-ripple div {
		position: absolute;
		border: 4px solid #ffffff;
	}
}