@import '../../../constants';

.project-card {
    box-shadow: rgba(35, 35, 35, 0.314) 0px 2px 5px;
    border-radius: 15px;
    padding: 20px;
    flex: 1 0 21%;
    max-width: 21%;
    transition: 0.2s ease-out;
    color: #cbcbcb;
    margin: 5px;

    @media only screen and (max-width: 1400px) {
        flex: 1 0 29%;
        max-width: 27%;
    }

    @media only screen and (max-width: 1000px) {
        flex: 1 0 45%;
        max-width: 41%;
    }

    @media only screen and (max-width: 700px) {
        max-width: calc(100% - 50px);
        flex: none;
	}
	
	&:hover {
		box-shadow: #232323 0px 2px 10px;
		transition: 0.2s ease-out;
		transform: scale(1.025);
			
		&.public {
			will-change: filter;
			cursor: pointer;
		}
	}

    .content {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        p {
            text-align: start;
        }

        .ext {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .title {
            font-weight: 600;
            font-size: 1.3em;
            padding-right: 10px;
            max-width: calc(100% - 50px);
            word-wrap: break-word;
        }

        .description {
            font-weight: 300;
            font-size: 1em;
        }

        .languages {
            font-size: 1.1em;
            padding-right: 10px;
        }

        .icon {
            color: white;
			transition-duration: 0.8s;
			position: relative;

			&.public {
				&:hover {
					color: rgb(175, 175, 175);
					transition-duration: 0s;
					transform: scale(1.1);
				}
	
				&:active {
					color: rgb(110, 110, 110);
					transition: 0.02s ease-in;
					transform: scale(0.8);
				}
			}
        }

        .middle {
            padding-top: 20px;
            padding-bottom: 20px;
            flex-grow: 1;
        }
    }
}