@import '../../constants';

.language-switch {
    position: fixed;
    transition: 2s;

    a {
        right: 0;
        bottom: 0;
        margin: 15px;
        position: fixed;
        background-color: transparent;
        border-width: 0px;
        transition: 0.2s ease-out;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #333333;
        user-select: none;
        transition: color 0.5s ease;

        @media only screen and (max-width: 380px) {
            color: transparent;
            pointer-events: none;
            cursor: default;
        }

        &:hover {
            cursor: pointer;
        }

        &:active {
            transition: 0.4s ease-out;
            color: #232323;
        }

        &:active>div>p {
            transition: 0.4s ease-out;
            background-color: #232323;
        }

        p {
            margin: 0 0 5px 0;
            font-size: 15px;
            text-transform: uppercase;
            color: #cdcdcd;
            background-color: #333333;
            padding: 3px 5px;
            border-radius: 5px;
            box-shadow: #23232350 0px 2px 2px;
        }

        .icon {
            width: 50px;
        }
    }
}