@import '../constants';
$top-gradient: rgba(209, 208, 208, 0.188);
$bottom-gradient: rgba(209, 208, 208, 1);

.app {
    .bg {
        height: 100vh;
        width: 100vw;
        background: linear-gradient(0deg, $bottom-gradient, $top-gradient), url('./background_blur.jpg') no-repeat center center;
        background-size: cover;
        filter: blur(4px);
        position: fixed;
        transform: scale(1.1, 1.1);

        @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background: -ms-linear-gradient(top, $top-gradient 0%, $bottom-gradient 100%), url('./background_blur_ie.jpg') no-repeat center center;
            background-size: cover;
        }

        @supports (-ms-ime-align: auto) {
            background: linear-gradient(to bottom, $top-gradient 0%, $bottom-gradient 100%), url('./background_blur_ie.jpg') no-repeat center center;
            background-size: cover;
            filter: blur(1px);
        }
    }

    .blur {
        transition: box-shadow, background-color, filter 0.5s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .overlay {
        background-color: $bottom-gradient;
        box-shadow: rgba(35, 35, 35, 0.251) 0px -2px 10px;
    }
}