@import '../../constants';

.nav {
    width: 100vw;
    bottom: 0px;

    ul {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100vw;
        padding: 0;
        list-style: none;
        text-align: center;

        a {
            padding: 5px;
            margin: 0px 10px 10px 10px;
            cursor: pointer;
            transition-duration: 0.2s;
            color: $nav-link-color;
            user-select: none;
            text-decoration: none;
            position: relative;
            

            &.selected {
                font-weight: 800;

                &:after {
                    width: 85%;
                    left: 7.5%;
                    border-radius: 1px;
                    height: 2px !important;
                }
            }

            &:hover {
                color: $nav-link-hover-color;

                &:after {
                    background: $nav-link-hover-color;
                    width: 100%;
                    left: 0;
                    height: 1px;
                }
            }

            &:active {
                color: $nav-link-color;
            }

            &:after {
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: "";
                display: block;
                height: 1px;
                left: 50%;
                position: absolute;
                background: $nav-link-color;
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                width: 0;
            }
        }
    }
}