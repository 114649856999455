@import '../../../constants';

.home {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	color: $nav-link-color;
	
    .picture-container {
        position: relative;
        height: 300px;
        width: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        user-select: none;

        h1 {
            text-align: center;
            user-select: text;
        }

        .picture {
            height: 300px;
            width: 300px;
            border-radius: 150px;
            box-shadow: $profile-shadow 0px 4px 24px;
            transition-duration: 0.4s;
            background-image: url('./profile-original.jpg'), url('./profile.jpg');
            background-size: cover;

            &:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
            &:active {
                transition: 0.2s ease-out;
                transform: scale(1.08);
            }
        }

        .bubble {
            height: 60px;
            width: 60px;
            margin: 15px;
            border-radius: 30px;
            z-index: 5;
            background-color: white;
            right: 0px;
            bottom: 0;
            position: absolute;
            box-shadow: $profile-shadow 0px 4px 24px;
            transition-duration: 0.8s;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $nav-link-color;

            a {
                color: $nav-link-color;
            }

            &:hover {
                transition: 0.5s ease;
                right: -100px;
                width: 180px;
            }

            .social {
                justify-content: space-evenly;
                flex: 1 1 auto;
				width: 100%;
				
				@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					justify-content: space-around;
					padding: 0px 5%;
				}

				@supports (-ms-ime-align: auto) {
					justify-content: space-around;
					padding: 0px 5%;
				}

                .icon { 
                    cursor: pointer;
                    font-size: 25px;
                    transition-duration: 0.8s;
    
                    &:hover {
                        transition: 0.1s ease-out;
                        color: $social-icon-hover;
                    }
    
                    &:active {
                        transition: 0.2s ease-out;
                        transform: scale(0.95);
                        color: $profile-shadow;
                    }
                }
            }
        }
    }
}

@media only screen and (max-height: 650px) {
    .home {
		justify-content: flex-start;

        h1 {
			font-size: calc(6px + 3.5vh);
        }

        .picture-container {
            height: 40vh;
			width: 40vh;
			min-height: 150px;
			min-width: 150px;
			margin-top: 20vh;

            .picture {
                height: 40vh;
                width: 40vh;
				min-height: 150px;
				min-width: 150px;
			}
			
            .bubble {
                height: 10vh;
                width: 10vh;
                right: -3vh;
				font-size: 3vh;
				min-height: 35px;
				min-width: 35px;
				
                &:hover {
                    right: -13vh;
                    width: 38vh;
                }

                .social {
                    .icon { 
                        font-size: 5vh;
                    }
                }
			}
		}
    }
}

@media only screen and (max-width: 320px) {
    .home {
        h1 {
            font-size: 10vw;
        }

        ReactTextTransition {
            font-size: 6vw;
        }
    }
}

@media only screen and (max-width: 500px) {
    .home {

        .picture-container {
            height: 60vw;
            width: 60vw;

            .picture {
                height: 60vw;
                width: 60vw;
            }

            .bubble {
                height: 13vw;
                margin: 3.75vw;
                font-size: 4vw;
                right: -13vw;
                width: 38vw;

                &:hover {
                    right: -13vw;
                    width: 38vw;
                }

                .social {
                    .icon { 
                        font-size: 6vw;
                    }
                }
            }
        }
    }
}

